@import "./fonts/fonts.css";

body {
  margin: 0;
  font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
  overflow: visible !important;
  box-sizing: border-box !important;
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  margin: 10px 0px 0px 0px;
}


:is(h1, h2, h3, h4, h5, h6) {
  font-family: "Klavika";
}
